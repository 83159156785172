(function($) {
    'use strict';

    var ResizeManage = function() {
        this.initialize.apply(this, arguments);
    };
    ResizeManage.prototype = {
        initialize: function(breakpoint) {
            var _this = this;
            this.$window = $(window);
            this.$element = $('<div></div>');
            this.isMobile = this.$window.width() < breakpoint;

            this.$window.on('resize', function() {
                if (_this.$window.width() >= breakpoint) {
                    if (_this.isMobile) {
                        _this.isMobile = false;
                        _this.$element.triggerHandler('resizeManage');
                    }
                } else {
                    if (!_this.isMobile) {
                        _this.isMobile = true;
                        _this.$element.triggerHandler('resizeManage');
                    }
                }
            });
        }
    };

    var scrollTarget = function($target, duration, callback) {
        var $window = $(window),
            targetY;

        if ($target.length > 0) {
            targetY = Math.ceil($target.offset().top);

            if ($window.width() < 980) {
                targetY -= $('.l-header').height();
            }
            if (duration === 0) {
                $window.scrollTop(targetY);
                callback();
            } else {
                $.when(
                    $('html, body').animate({scrollTop: targetY}, duration, 'swing')
                ).done(function() {
                    callback();
                });
            }
        }
    };

    var Accordion = function() {
        this.initialize.apply(this, arguments);
    };
    Accordion.prototype = {
        initialize: function(selector, selectorClose) {
            this.$item = $(selector);
            this.selectorClose = selectorClose;
        },
        enable: function() {
            var _this = this;

            this.$item.each(function() {
                var $head = $(this);
                var $body = $head.next();
                var $close = typeof _this.selectorClose === 'undefined' ? $() : $body.find(_this.selectorClose);

                $head.on('click.accordion', function() {
                    $head.triggerHandler('changeAccordion', [$head.hasClass('-open') ? 'close' : 'open', $head]);
                });
                $body.attr('aria-expanded', $head.hasClass('-open') ? 'true' : 'false');
                $body.css('display', $body.css('display'));
                $close.on('click.accordion', function() {
                    $head.triggerHandler('changeAccordion', ['close', $head]);
                    return false;
                });
            });
            this.$item.on('changeAccordion', function(event, mode, $head) {
                var $body;

                if (mode === 'open') {
                    _this.$item.not($head).each(function() {
                        var $this = $(this);

                        if ($this.hasClass('-open')) {
                            _this.$item.triggerHandler('changeAccordion', ['close', $this]);
                        }
                    });
                }
                if (mode === 'open' || mode === 'close') {
                    $body = $head.next();
                    $body.stop(true, false).slideToggle(300);
                    $head.toggleClass('-open');
                    $body.attr('aria-expanded', $head.hasClass('-open') ? 'true' : 'false');
                }
            });
        },
        disable: function() {
            var _this = this;

            this.$item.each(function() {
                var $head = $(this);
                var $body = $head.next();
                var $close = typeof _this.selectorClose === 'undefined' ? $() : $body.find(_this.selectorClose);

                $head.off('click.accordion changeAccordion');
                $body.css({
                    'display': '',
                    'height': '',
                    'margin-top': '',
                    'margin-bottom': '',
                    'padding-top': '',
                    'padding-bottom': ''
                }).removeAttr('aria-expanded');
                $close.off('click.accordion');
            });
        }
    };

    function bothAccordion(selector, selectorClose) {
        var accordion = new Accordion(selector, selectorClose);

        if (accordion.$item.length === 0) {
            return;
        }

        accordion.enable();
    }

    function mobileAccordion(selector, selectorClose) {
        var accordion = new Accordion(selector, selectorClose);
        var resizeManage;

        if (accordion.$item.length === 0) {
            return;
        }

        resizeManage = new ResizeManage(980);
        if (resizeManage.isMobile) {
            accordion.enable();
        }
        resizeManage.$element.on('resizeManage', function() {
            if (resizeManage.isMobile) {
                accordion.enable();
            } else {
                accordion.disable();
            }
        });
    }

    function hashScroll() {
        var $document = $(document),
            protocolRegexp = /^https?:\/\//,
            currentAnchor = window.location.href.split('#');

        $document.on('click', 'a', function(event) {
            var thisAnchor = this.href.split('#'),
                $target = $document.find('#' + thisAnchor[1]);

            if (event.isDefaultPrevented() || !protocolRegexp.test(event.currentTarget.href) || event.currentTarget.target === '_blank') {
                return false;
            }
            if (currentAnchor[0] === thisAnchor[0] && thisAnchor.length > 1 && $target.length === 1) {
                scrollTarget($target, 400, $.noop);
                event.preventDefault();
            }
        });
        $(window).on('load', function() {
            var $target = $document.find(window.location.hash);

            if (window.location.hash && $target.length === 1) {
                scrollTarget($target, 400, $.noop);
                if ('replaceState' in window.history) {
                    window.history.replaceState(
                        '',
                        document.title,
                        window.location.pathname + window.location.search
                    );
                }
            }
        });
    }

    function mobileSearchMenu() {
        var $container = $('.l-container');
        var scrollPosition = 0;

        if (document.querySelector('.l-header') === null) {
            return;
        }

        new Vue({
            el: '.l-header',
            data: {
                isSearchOpen: false,
                isMypageOpen: false,
                isMobile: false,
                isScrollFixed: false,
                isHoverNavi: false
            },
            computed: {
                isMask: function() {
                    return this.isSearchOpen || this.isHoverNavi;
                }
            },
            watch: {
                isMask: function() {
                    if (this.isMask) {
                        $container.addClass('-mask');
                    } else {
                        $container.removeClass('-mask');
                    }
                }
            },
            mounted: function() {
                var resizeManage = new ResizeManage(980);
                var _this = this;

                this.isMobile = resizeManage.isMobile;
                if (!this.isMobile) {
                    this.enableHoverNavi();
                }
                resizeManage.$element.on('resizeManage', function() {
                    _this.isMobile = resizeManage.isMobile;
                    if (resizeManage.isMobile) {
                        _this.disableHoverNavi();
                    } else {
                        _this.isSearchOpen = false;
                        _this.isMypageOpen = false;
                        _this.enableHoverNavi();
                        _this._manageScroll();
                    }
                });
            },
            methods: {
                searchSwitch: function() {
                    if (this.isMobile) {
                        if (this.isMypageOpen) {
                            this.isMypageOpen = false;
                        }
                    }
                    this.isSearchOpen = !this.isSearchOpen;
                    if (this.isMobile) {
                        this._manageScroll();
                    }
                },
                mypageSwitch: function() {
                    if (this.isMobile) {
                        if (this.isSearchOpen) {
                            this.isSearchOpen = false;
                        }
                        this.isMypageOpen = !this.isMypageOpen;
                        if (this.isMobile) {
                            this._manageScroll();
                        }
                    }
                },
                enableHoverNavi: function() {
                    var _this = this;

                    $(this.$el).find('.l-header__navItem.-arrow').on({
                        'mouseenter.headerHoverNavi': function() {
                            _this.isHoverNavi = true;
                        },
                        'mouseleave.headerHoverNav': function() {
                            _this.isHoverNavi = false;
                        }
                    });
                },
                disableHoverNavi: function() {
                    $(this.$el).find('.l-header__navItem.-arrow').off('.headerHoverNavi');
                    this.isHoverNavi = false;
                },
                _manageScroll: function() {
                    if (this.isSearchOpen || this.isMypageOpen) {
                        if (!this.isScrollFixed) {
                            this.isScrollFixed = true;
                            scrollPosition = $(window).scrollTop();
                            $container.css({
                                'position': 'fixed',
                                'width': '100%',
                                'top': -scrollPosition + 'px'
                            });
                        }
                    } else if (this.isScrollFixed) {
                        this.isScrollFixed = false;
                        $container.css({
                            'position': '',
                            'width': '',
                            'top': ''
                        });
                        $(window).scrollTop(scrollPosition);
                    }
                }
            }
        });
    }

    function topSlider() {
        var $slider = $('.swiper-container').filter(function() {
            return $(this).children('.p-top__sliderList.swiper-wrapper').length !== 0;
        });

        if ($slider.length === 0) {
            return;
        }

        $slider.each(function() {
            new Swiper(this, {
                loop: true,
                navigation: {
                    nextEl: this.querySelector('.swiper-button-next'),
                    prevEl: this.querySelector('.swiper-button-prev')
                },
                speed: 600
            });
        });
    }

    function topRecommendSlider() {
        var resizeManage = new ResizeManage(980);
        var $slider = $('.swiper-container').filter(function() {
            return $(this).children('.p-top__recommendList.swiper-wrapper').length !== 0;
        });
        var carousel = {
            activeDesktop: function() {
                var _this = this;
                $slider.each(function() {
                    new Swiper(this, {
                        slidesPerView: 3,
                        navigation: {
                            nextEl: this.querySelector('.swiper-button-next'),
                            prevEl: this.querySelector('.swiper-button-prev')
                        },
                        on: {
                            init: function() {
                                _this.setTitleHeight(this);
                            },
                            resize: function() {
                                _this.setTitleHeight(this);
                            }
                        }
                    });
                });
            },
            activeMobile: function() {
                var _this = this;
                $slider.each(function() {
                    new Swiper(this, {
                        slidesPerView: 'auto',
                        centeredSlides: true,
                        spaceBetween: 13,
                        navigation: {
                            nextEl: this.querySelector('.swiper-button-next'),
                            prevEl: this.querySelector('.swiper-button-prev')
                        },
                        on: {
                            init: function() {
                                _this.setTitleHeight(this);
                            },
                            resize: function() {
                                _this.setTitleHeight(this);
                            }
                        }
                    });
                });
            },
            setTitleHeight: function(swiperObject) {
                var $title = $(swiperObject.slides).find('.c-productMain__title');
                var maxHeight = 0;

                $title.css('height', '');
                $title.each(function() {
                    var thisHeight = this.clientHeight;

                    if (maxHeight < thisHeight) {
                        maxHeight = thisHeight;
                    }
                });
                $title.css('height', maxHeight + 'px');
            },
            active: function() {
                if (resizeManage.isMobile) {
                    this.activeMobile();
                } else {
                    this.activeDesktop();
                }
            },
            inactive: function() {
                $slider.each(function() {
                    this.swiper.destroy(true, true);
                });
            }
        };

        if ($slider.length === 0) {
            return;
        }

        carousel.active();
        resizeManage.$element.on('resizeManage', function() {
            carousel.inactive();
            carousel.active();
        });
    }

    function topNewItemSlider() {
        var resizeManage = new ResizeManage(980);
        var $slider = $('.swiper-container').filter(function() {
            var $list = $(this).children('.p-top__newItem__list.swiper-wrapper'),
                itemLength = $list.find('.p-top__newItem__item').length;

            return $list.length !== 0 && itemLength > 1;
        });
        var carousel = {
            active: function() {
                $slider.each(function() {
                    new Swiper(this, {
                        loop: true,
                        slidesPerView: 'auto',
                        centeredSlides: true,
                        spaceBetween: 15
                    });
                });
            },
            inactive: function() {
                $slider.each(function() {
                    this.swiper.destroy(true, true);
                });
            }
        };

        if ($slider.length === 0) {
            return;
        }

        if (resizeManage.isMobile) {
            carousel.active();
        }
        resizeManage.$element.on('resizeManage', function() {
            if (resizeManage.isMobile) {
                carousel.active();
            } else {
                carousel.inactive();
            }
        });
    }

    function productMainSlider() {
        var $slider = $('.swiper-container').filter(function() {
            return $(this).children('.c-productMain__thumb.swiper-wrapper').length !== 0;
        });

        if ($slider.length === 0) {
            return;
        }

        $slider.each(function() {
            var isNested = $(this).parent().closest('.swiper-container').length !== 0;
            var instance;
            var $thumbnailList;
            var $thumbnailItem;

            instance = new Swiper(this, $.extend({
                nested: isNested,
                loop: true,
                simulateTouch: false
            }, this.querySelectorAll('.swiper-slide').length < 2 ? {} : {
                pagination: {
                    el: this.querySelector('.swiper-pagination'),
                    clickable: true
                }
            }));

            $thumbnailList = $(this).closest('.c-productMain').find('.c-productLabel__list');
            if ($thumbnailList.length === 1) {
                $thumbnailItem = $thumbnailList.find('li');
                $thumbnailItem.on('click', function() {
                    instance.slideTo($thumbnailItem.index(this) + 1);
                });
                $thumbnailItem.eq(this.swiper.activeIndex - 1).addClass('-active');
                this.swiper.on('slideChange', function() {
                    $thumbnailItem.eq(this.previousIndex - 1).removeClass('-active');
                    $thumbnailItem.eq(this.activeIndex - 1).addClass('-active');
                });
            }
        });
    }

    function favoriteButton() {
        var $container = $('.l-container');

        $container.on('click', '.c-productItem__favBtn', function(event) {
            var data = {};
            data.transactionid = $(this).data('transactionid');

            if ($(this).hasClass('-active')) {
                var url = '/mypage/favorite.php';
                data.mode = 'delete_favorite_api';
                data.product_id = $(this).data('product-id');
            } else {
                var url = '/products/detail.php?product_id=' + $(this).data('product-id');
                data.mode = 'add_favorite';
                data.favorite_product_id = $(this).data('product-id');
            }
            $.ajax({
                url: url,
                type: 'POST',
                cache: false,
                data: data
            })
            // Ajaxリクエストが成功した時発動
                .done(function(data) {
                    $(event.target).toggleClass('-active');
                })
            // Ajaxリクエストが失敗した時発動
                .fail(function(data) {
                })
            // Ajaxリクエストが成功・失敗どちらでも発動
                .always(function(data) {
                });

        });

    }

    function setupSameAsLeftEyeButton() {
        $('#same_as_left_eye').off('click').on('click', function() {

            // 乱視の制限値によるPWRプルダウン更新があるので、2回コールする.
            updateSameAsLeftEyeButtonState();
            updateSameAsLeftEyeButtonState();
            // 右眼をレンズ度数指定に変更する.
            $('select#right_how').val(3);
            // updateHowSelect();
        })
    }

    function updateSameAsLeftEyeButtonState() {
        var isChecked = $('#same_as_left_eye').prop('checked');

        // Howセレクトの更新.
        if (isChecked) {
            $('#right_how').css(disable_style);
        }else{
            $('#right_how').css(enable_style);
        }

        for (var ii = 0; ii < buyModalFormKeys.length; ii++) {
            var key = buyModalFormKeys[ii].key;
            var label = buyModalFormKeys[ii].label;
            var left_select = $('#left_' + key + ':visible');
            var right_select = $('#right_' + key + ':visible');

            if (isChecked) {
                right_select.val(left_select.val());
                right_select.css(disable_style);
            } else {
                right_select.css(enable_style);
            }
        }

        updateCartButtonState();
    }

    // 各セレクト値.
    //  選択肢がない場合はdiv.c-select2をremoveしてdiv.-grid_row__innerをappendし、
    //  中にプレーンテキストで BC : 8.5 などとする.
    var buyModalFormKeys = [
        {'key': 'axis', 'label': 'AXIS'},
        {'key': 'cyl', 'label': 'CYL'},
        {'key': 'pwr', 'label': 'PWR'},
        {'key': 'bc', 'label': 'BC'},
        {'key': 'dia', 'label': 'DIA'},
        {'key': 'addition', 'label': 'ADD'},
        {'key': 'colors', 'label': 'カラー'},
    ];

    var disable_style = {opacity: .5, 'pointer-events': 'none'};
    var enable_style = {opacity: 1, 'pointer-events': 'auto'};

    function resetProductSelects() {
        for (var ii = 0; ii < buyModalFormKeys.length; ii++) {
            var key = buyModalFormKeys[ii].key;
            var left_select = $('#left_' + key + ':visible');
            var right_select = $('#right_' + key + ':visible');
            left_select.val('');
            right_select.val('');
        }
    }

    // カートに入れるボタンのステータスを更新する.
    function updateCartButtonState() {

        var left_done = 0;
        var right_done = 0;
        var left_select_count = $('select[id^=left_]:visible').length;
        var right_select_count = $('select[id^=right_]:visible').length;

        for (var ii = 0; ii < buyModalFormKeys.length; ii++) {
            var key = buyModalFormKeys[ii].key;
            var label = buyModalFormKeys[ii].label;
            var left_select = $('#left_' + key + ':visible');
            var right_select = $('#right_' + key + ':visible');

            if (left_select.length === 1 && left_select.val() !== '') {
                left_done++;
            }
            if (right_select.length === 1 && right_select.val() !== '') {
                right_done++;
            }
        }

        // カートに入れるボタンの有効、無効.
        if ($('#eyeType').val() == 1) {
            if ((left_done == left_select_count - 1 && right_done == right_select_count - 1) || (left_done == left_select_count - 1 && right_done == 0) || (right_done == right_select_count - 1 && left_done == 0)) {
                $('.c-btn.-buy').css(enable_style);
            } else {
                $('.c-btn.-buy').css(disable_style);
            }

            // 数量ボタンの有効、無効.
            if (left_done == left_select_count - 1) {
                $('.p-item__count.left .p-item__amountMinus').css(enable_style);
                $('.p-item__count.left .p-item__amountPlus').css(enable_style);
                $('.p-item__count.left .p-item__amountInput').prop('disabled', false);
            } else {
                $('.p-item__count.left .p-item__amountMinus').css(disable_style);
                $('.p-item__count.left .p-item__amountPlus').css(disable_style);
                $('.p-item__count.left .p-item__amountInput').prop('disabled', true);
            }
            if (right_done == right_select_count - 1) {
                $('.p-item__count.right .p-item__amountMinus').css(enable_style);
                $('.p-item__count.right .p-item__amountPlus').css(enable_style);
                $('.p-item__count.right .p-item__amountInput').prop('disabled', false);
            } else {
                $('.p-item__count.right .p-item__amountMinus').css(disable_style);
                $('.p-item__count.right .p-item__amountPlus').css(disable_style);
                $('.p-item__count.right .p-item__amountInput').prop('disabled', true);
            }

        }
        // 両眼商品の場合は左右のプルダウンが埋まっているか確認する.
        else if ($('#eyeType').val() == 2) {
            if ((left_done == left_select_count - 1 && right_done == right_select_count - 1)) {
                $('.c-btn.-buy').css(enable_style);
            } else {
                $('.c-btn.-buy').css(disable_style);
            }

            // 数量ボタンの有効、無効.
            if (left_done == left_select_count - 1 && right_done == right_select_count - 1) {
                $('.p-item__count.both .p-item__amountMinus').css(enable_style);
                $('.p-item__count.both .p-item__amountPlus').css(enable_style);
            } else {
                $('.p-item__count.both .p-item__amountMinus').css(disable_style);
                $('.p-item__count.both .p-item__amountPlus').css(disable_style);
            }
        }

        // コンタクト以外商品の場合はカートボタンを有効にする.
        if ($('#shoppingType').val() == '2') {
            $('.c-btn.-buy').css(enable_style);
        }

        // 左眼と同じにするチェックの有効、無効.
        if (left_done == left_select_count - 1) {
            $('label[for=same_as_left_eye]').css(enable_style);
            $('#same_as_left_eye').removeAttr('disabled');
        } else {
            $('label[for=same_as_left_eye]').css(disable_style);
            $('#same_as_left_eye').attr('disabled', 'disabled');
        }

        // 乱視の項目がある場合はPWRの制限値をチェックする.
        if ($('#left_cyl:visible').length == 1 && $('#left_axis:visible').length == 1) {
            var left_axis = $('#left_axis:visible').val();
            var left_cyl = $('#left_cyl:visible').val();
            if (left_axis !== '' && left_cyl !== '') {
                var restrict_data = $('#left_pwr').data('cyl_restrict');
                if (typeof restrict_data['data'][left_axis] !== 'undefined' && typeof restrict_data['data'][left_axis][left_cyl] !== 'undefined') {
                    updatePwrWithRestrict($('#left_pwr'), restrict_data['data'][left_axis][left_cyl]);
                }
            }
        }
        if ($('#right_cyl:visible').length == 1 && $('#right_axis:visible').length == 1) {
            var right_axis = $('#right_axis:visible').val();
            var right_cyl = $('#right_cyl:visible').val();
            if (right_axis !== '' && right_cyl !== '') {
                var restrict_data = $('#right_pwr').data('cyl_restrict');
                if (typeof restrict_data['data'][right_axis] !== 'undefined' && typeof restrict_data['data'][right_axis][right_cyl] !== 'undefined') {
                    updatePwrWithRestrict($('#right_pwr'), restrict_data['data'][right_axis][right_cyl]);
                }
            }
        }

        // BC選択値によるPWRの有効、無効.
        $('#left_pwr option').each(function() {
            var restrict_data = $(this).data('attr_restrict');
            if (restrict_data && restrict_data['bc']) {

                // 選択BCにPWRが存在している場合.
                if (typeof restrict_data['bc'][$('#left_bc').val()] !== 'undefined') {

                    if ($(this).parent().hasClass('display-none-container')) {
                        var parent_span = $(this).parent();
                        $(this).parent().after($(this));
                        parent_span.remove();
                    }
                    $(this).prop('disabled', false);
                    $(this).css('display', '');
                }
                // 選択BCにPWRが存在していない場合.
                else{
                    // display:none;がiosで効かないので,spanで囲う.
                    if (!$(this).parent().hasClass('display-none-container')) {
                        var span = $('<span>');
                        $(this).after(span);
                        span.css('display', 'none');
                        span.attr('class', 'display-none-container');
                        span.append($(this));
                    }
                    // $(this).prop("disabled", true);
                    // $(this).css("display", "none");
                }
            }
        });
        $('#right_pwr option').each(function() {
            var restrict_data = $(this).data('attr_restrict');
            if (restrict_data && restrict_data['bc']) {

                // 選択BCにPWRが存在している場合.
                if (typeof restrict_data['bc'][$('#right_bc').val()] !== 'undefined') {

                    if ($(this).parent().hasClass('display-none-container')) {
                        var parent_span = $(this).parent();
                        $(this).parent().after($(this));
                        parent_span.remove();
                    }
                    $(this).prop('disabled', false);
                    $(this).css('display', '');
                }
                // 選択BCにPWRが存在していない場合.
                else{
                    // display:none;がiosで効かないので,spanで囲う.
                    if (!$(this).parent().hasClass('display-none-container')) {
                        var span = $('<span>');
                        $(this).after(span);
                        span.css('display', 'none');
                        span.attr('class', 'display-none-container');
                        span.append($(this));
                    }
                    // $(this).prop("disabled", true);
                    // $(this).css("display", "none");
                }
            }
        });

        // 各更新処理の結果、SELECTの選択状態がNULLになっていたら未選択に戻す.
        for (var ii = 0; ii < buyModalFormKeys.length; ii++) {
            var key = buyModalFormKeys[ii].key;
            var left_select = $('#left_' + key + ':visible');
            var right_select = $('#right_' + key + ':visible');

            if (left_select.val() == null) {
                left_select.val('');
            }
            if (right_select.val() == null) {
                right_select.val('');
            }
        }
    }

    function updatePwrWithRestrict(select, data) {
        select.find('option').each(function() {
            var digitValue = parseFloat($(this).attr('value'));
            if (digitValue < data.min || digitValue > data.max) {
                $(this).prop('disabled', true);
                $(this).css('display', 'none');
            }else{
                $(this).prop('disabled', false);
                $(this).css('display', '');
            }
        });

    }

    /****
     * 購入方法プルダウンの更新。
     */
    function updateHowSelect() {
        var left_how = $('select#left_how');
        var right_how = $('select#right_how');
        /*
          <option value="1">当サイトで前回ご注文の度数</option>
          <option value="2">店舗で前回ご注文の度数</option>
          <option value="3">レンズ度数を指定</option>
          <option value="4">左目は購入しない</option>
         */

        /***********************************************
         * 左眼
         ************************************************/
        for (var ii = 0; ii < buyModalFormKeys.length; ii++) {
            var key = buyModalFormKeys[ii].key;
            var left_select = $('#left_' + key + ':visible');

            if (left_how.val() == 4) {
                left_select.val('');
                left_select.css(disable_style);
            } else {
                left_select.css(enable_style);
            }
        }

        /***********************************************
         * 右眼
         ************************************************/
        for (var ii = 0; ii < buyModalFormKeys.length; ii++) {
            var key = buyModalFormKeys[ii].key;
            var right_select = $('#right_' + key + ':visible');

            if (right_how.val() == 4) {
                right_select.val('');
                right_select.css(disable_style);

                // 左眼と同じにするをdisableにする.
                $('#same_as_left_eye').prop('checked', false);

            } else if (right_how.val() == 1 || right_how.val() == 2) {
                // 左眼と同じにするをdisableにする.
                $('#same_as_left_eye').prop('checked', false);

            }else{
                right_select.css(enable_style);
            }
        }


        // 両眼商品の場合は option value=4は選択不可とする.
        if ($('#eyeType').val() == 2) {
            $('#left_how option[value=4]').prop('disabled', true)
            $('#right_how option[value=4]').prop('disabled', true)
        } else {
            $('#left_how option[value=4]').prop('disabled', false)
            $('#right_how option[value=4]').prop('disabled', false)
        }

        updateCartButtonState();


        // 当サイトで前回ご注文の度数.
        if (left_how.val() == 1) {
            restoreLeftDefaultValues(left_how.data('default_value'));
        }

        // 当サイトで前回ご注文の度数.
        if (right_how.val() == 1) {
            restoreRightDefaultValues(right_how.data('default_value'));
        }
    }

    function applyProductInfos($modalElement, data) {

        // 商品タイプ（コンタクト＝１、その他＝２）.
        var shoppingType = data.shopping_type;

        // 商品ID.
        $modalElement.find('#modal_product_id').val(data.product_id);
        // 商品規格ID.
        $modalElement.find('#modal_product_class_id').val(data.product_class_id);
        // 商品名.
        $modalElement.find('#ttl_product_name').text(data.name);
        // メーカー画像.
        $modalElement.find('#ttl_product_maker').attr('src', '/img/common/maker_logo_' + data.maker_id + '.png');
        $modalElement.find('#ttl_product_maker').attr('alt', data.maker_name);

        // ----------------------------------------------------------------------------------
        // コンタクト商品の場合.
        // ----------------------------------------------------------------------------------
        if (shoppingType == 1) {

            // スペック.
            // 両眼1ヶ月分 30枚入 1箱
            var specString = '';
            if (data.eye_type == 1) {
                specString = '片眼';
                $('.p-item__count.right').show();
                $('.p-item__count.left').show();
                $('.p-item__count.both').hide();
            } else {
                specString = '両眼';
                $('.p-item__count.right').hide();
                $('.p-item__count.left').hide();
                $('.p-item__count.both').show();
                $('.js-modal').addClass('-verA');
            }

            // 定期商品の場合は個数表示を消す.
            if (data.product_type_id != 1) {
                $('.p-item__count.right').hide();
                $('.p-item__count.left').hide();
                $('.p-item__count.both').hide();
                $('.js-modal').addClass('-verB');
            }

            specString += data.days_per_box;
            specString += ' ' + data.amount_per_box + '枚入';
            specString += ' ' + data.box_count + '箱';
            $modalElement.find('#ttl_product_spec').text(specString);

            // 購入方法プルダウン.
            $('select#left_how').data('default_value', data['default_select_value']);
            $('select#left_how').off('change').on('change', function() {
                updateHowSelect();
            });
            $('select#right_how').data('default_value', data['default_select_value']);
            $('select#right_how').off('change').on('change', function() {
                updateHowSelect();
            });

            // 乱視用の制限値を格納しておく.
            $('#left_pwr').data('cyl_restrict', data.extra_info['cyl_restrict']);
            $('#right_pwr').data('cyl_restrict', data.extra_info['cyl_restrict']);

            for (var ii = 0; ii < buyModalFormKeys.length; ii++) {
                var key = buyModalFormKeys[ii].key;
                var label = buyModalFormKeys[ii].label;
                var left_select = $('#left_' + key);
                var right_select = $('#right_' + key);

                // 左眼の選択肢の更新.
                left_select.off('change').on('change', function() {
                    // 選択肢を操作した時点で、度数を指定するに変更.
                    $('#left_how').val(3);
                    // 右目が「左目と同じ度数」にしていた場合、連動させる.
                    if ($('#same_as_left_eye').prop('checked')) {
                        updateSameAsLeftEyeButtonState();
                    }
                    updateCartButtonState();
                });

                // 右眼の選択肢の更新.
                right_select.off('change').on('change', function() {
                    // 選択肢を操作した時点で、度数を指定するに変更.
                    $('#right_how').val(3);
                    updateCartButtonState();
                });

                left_select.empty();
                right_select.empty();
                left_select.append('<option value="">' + label + '(指定して下さい)</option>');
                right_select.append('<option value="">' + label + '(指定して下さい)</option>');

                for (var option_value in data.extra_info[key]) {
                    var option = $('<option>');
                    var option_label = option_value;
                    var option_data = {};
                    // PWRの時はBCのチェック.
                    if (key === 'pwr') {
                        var m = option_value.match(/\((BC(.+?))\)(.+)/);
                        if (m!=null) {
                            var bc_split = m[1].split('|');
                            var bcs = {};
                            for(var jj=0;jj<bc_split.length;jj++) {
                                var bc = bc_split[jj].replace(/BC/g, '');
                                bcs[bc] = bc;
                            }
                            option_label = m[3];
                            option_data['bc'] = bcs;
                        }
                    }

                    option.attr('value', option_label);
                    option.text(option_label);
                    option.data('attr_restrict', option_data);
                    left_select.append(option.clone(true));
                    right_select.append(option.clone(true));
                }

                // 選択肢が存在しない場合は要素を非表示にする.
                if (Object.keys(data.extra_info[key]).length === 0) {
                    left_select.closest('.-grid_row').hide();
                    right_select.closest('.-grid_row').hide();
                }

                // 選択肢が1つの場合はプルダウンの代わりにラベル表示にする.
                if (Object.keys(data.extra_info[key]).length === 1) {
                    var first_key = Object.keys(data.extra_info[key])[0];

                    left_select.val(data.extra_info[key][first_key]);
                    right_select.val(data.extra_info[key][first_key]);
                    left_select.parent().hide();
                    right_select.parent().hide();

                    left_select.closest('.-grid_row').find('.-grid_row__inner').remove();
                    right_select.closest('.-grid_row').find('.-grid_row__inner').remove();
                    left_select.closest('.-grid_row').append('<div class=\'-grid_row__inner\'>' + label + ':' + data.extra_info[key][first_key]);
                    right_select.closest('.-grid_row').append('<div class=\'-grid_row__inner\'>' + label + ':' + data.extra_info[key][first_key]);
                } else {
                    left_select.closest('.-grid_row').find('.-grid_row__inner').remove();
                    right_select.closest('.-grid_row').find('.-grid_row__inner').remove();
                    left_select.parent().show();
                    right_select.parent().show();

                }

            }
        }

        // コンタクト以外の商品の場合.
        if (shoppingType == 2) {
            $('#left_column').hide();
            $('#right_column').hide();
            $('#column_parent').removeClass('c-buy__columns');
            $('#left_column').removeClass('c-buy__column');
            $('#left_column').removeClass('-left');
            $('.c-buy__attention').hide();
            $('.c-buy__notice').hide();

            // モーダルウィンドウタイトルの変更.
            $('.js-modal__contentWrapper .c-buy__head').html('&nbsp;');

            // 個数表示.
            $('.p-item__count.both').show();


        } else {
            $('#left_column').show();
            $('#right_column').show();
            $('#column_parent').addClass('c-buy__columns');
            $('#left_column').addClass('c-buy__column');
            $('#left_column').addClass('-left');
            $('.c-buy__attention').show();
            $('.c-buy__notice').show();

            // モーダルウィンドウタイトルの変更.
            $('.js-modal__contentWrapper .c-buy__head').html('レンズデータを入力');
        }

    }

    /********************
     * 初期選択値の復元.
     ********************/
    function restoreLeftDefaultValues(default_values) {
        for(var key in default_values) {
            if (key.match(/left_/) !== null) {
                var elem_key = key;
                if (elem_key.match(/_color/) !== null) {
                    elem_key = 'left_colors';
                }
                if ($('#' + elem_key + ' option[value=\'' + default_values[key] + '\']').length == 1) {
                    $('#' + elem_key).val(default_values[key]);
                }
            }
        }
    }

    function restoreRightDefaultValues(default_values) {
        for(var key in default_values) {
            if (key.match(/right_/) !== null) {
                var elem_key = key;
                if (elem_key.match(/_color/) !== null) {
                    elem_key = 'right_colors';
                }
                if ($('#' + key + ' option[value=\'' + default_values[key] + '\']').length == 1) {
                    $('#' + key).val(default_values[key]);
                }
            }
        }
    }

    function modal() {
        var $container = $('.l-container');
        var $modal = $('.js-modal');
        var isShow = false;
        var scrollPosition = 0;

        $container.on('click', '.js-modalBtn', function() {
            if (!isShow) {

                var product_id = $(this).data('product-id');

                // -------------------------------------------------------------
                // カートに入れるボタン.
                // -------------------------------------------------------------
                if (typeof product_id !== 'undefined') {
                    // 商品データ取得.

                    if (!product_id) {
                        return false;
                    }

                    var data = {};
                    data.mode = 'getInfoForModal';
                    data.product_id = $(this).data('product-id');
                    $.ajax({
                        url: '/products/detail.php',
                        type: 'GET',
                        cache: false,
                        data: data
                    })
                    // Ajaxリクエストが成功した時発動
                        .done(function(data) {
                            $('#eyeType').val(data.eye_type);
                            $('#shoppingType').val(data.shopping_type);
                            applyProductInfos($modal, data);
                            resetProductSelects();
                            // 購入ボタンをdisable.
                            updateCartButtonState();

                            // 左眼と同じにするボタン.
                            setupSameAsLeftEyeButton();
                            $('#same_as_left_eye').prop('checked', false);
                            updateSameAsLeftEyeButtonState();

                            // 購入方法プルダウンの初期化.
                            $('#left_how option[value=3]').prop('selected', true)
                            $('#right_how option[value=3]').prop('selected', true)
                            updateHowSelect();

                            // 個数をリセット.
                            $('.p-item__amountNumber').text(1);
                            $('.p-item__amountInput').val(1);


                            // デフォルト選択値の復元.
                            if (Object.keys(data['default_select_value']).length > 0) {
                                // 当サイトで前回ご購入の度数を表示
                                $('#left_how option[value=1]').show();
                                $('#right_how option[value=1]').show();
                                $('#left_how').val(1);
                                $('#right_how').val(1);

                                // BC選択後のPWRプルダウン項目更新があるので、updateCartButtonStateが2回必要.
                                restoreLeftDefaultValues(data['default_select_value']);
                                restoreRightDefaultValues(data['default_select_value']);
                                updateCartButtonState();
                                restoreLeftDefaultValues(data['default_select_value']);
                                restoreRightDefaultValues(data['default_select_value']);
                            }else{
                                // 当サイトで前回ご購入の度数を非表示
                                $('#left_how option[value=1]').hide();
                                $('#right_how option[value=1]').hide();
                            }

                            $modal.addClass('-show');
                            isShow = true;
                            scrollPosition = $(window).scrollTop();
                            $container.css({
                                'position': 'fixed',
                                'width': '100%',
                                'top': -scrollPosition + 'px'
                            });
                        })
                        // Ajaxリクエストが失敗した時発動
                        .fail(function(data) {
                        })
                        // Ajaxリクエストが成功・失敗どちらでも発動
                        .always(function(data) {
                        });

                }
                // -------------------------------------------------------------
                // それ以外のモーダル.
                // -------------------------------------------------------------
                else {
                    $modal.addClass('-show');
                    isShow = true;
                    scrollPosition = $(window).scrollTop();
                    $container.css({
                        'position': 'fixed',
                        'width': '100%',
                        'top': -scrollPosition + 'px'
                    });

                }
            }
            return false;
        });
        $container.on('click', '.js-modal__contentWrapper, .js-modal__closeBtn', function(event) {
            var $target = $(event.target);

            if ($target.hasClass('js-modal__contentWrapper') || $target.hasClass('js-modal__closeBtn')) {
                if (isShow) {
                    $modal.removeClass('-show');
                    isShow = false;
                    $container.css({
                        'position': '',
                        'width': '',
                        'top': ''
                    });
                    $(window).scrollTop(scrollPosition);
                }
                return false;
            }
        });
    }

    function modalAmount() {
        var $amount = $('.p-item__amountNumber').filter(function() {
            var $this = $(this);
            var $minus = $this.prev('.p-item__amountMinus');
            var $plus = $this.next('.p-item__amountPlus');

            return $minus.length !== 0 && $plus.length !== 0;
        });

        $amount.each(function() {
            var $number = $(this);
            var $minus = $number.prev('.p-item__amountMinus');
            var $plus = $number.next('.p-item__amountPlus');
            var $input = $plus.next('.p-item__amountInput');

            $minus.on('click', function() {
                var number = parseInt($number.text(), 10);

                number -= 1;
                if (number < 1) {
                    number = 1;
                }
                $number.text(number);
                if ($input.length !== 0) {
                    $input.val(number);
                }
                return false;
            });
            $plus.on('click', function() {
                var number = parseInt($number.text(), 10);

                number += 1;
                if (number > 99) {
                    number = 99;
                }
                $number.text(number);
                if ($input.length !== 0) {
                    $input.val(number);
                }
                return false;
            });
        });
    }

    function selectHashscroll() {
        var $select = $('.js-select-hashscroll');

        $select.on('change', function() {
            var value = $(this).val(),
                $target = $(document).find('#' + value);

            if ($target.length !== 0) {
                scrollTarget($target, 400, $.noop);
            }
        });
    }

    hashScroll();
    mobileSearchMenu();
    mobileAccordion('.l-header__navHead.js-accordion');
    mobileAccordion('.l-footer__navHead.js-accordion');
    bothAccordion('.p-top__newItem__newsHead.js-accordion');
    bothAccordion('.p-productInfo__head.js-accordion', '.js-accordion__close');
    bothAccordion('.p-static__accordionHead.js-accordion', '.js-accordion__close');
    topSlider();
    topRecommendSlider();
    topNewItemSlider();
    productMainSlider();
    favoriteButton();
    modal();
    modalAmount();
    selectHashscroll();
})(jQuery);
